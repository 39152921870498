<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        username: "",
        phone: "",
        email: "",
        password: "",
      },
      submitted: false,
      tryingToRegister: false,
      isRegisterError: false,
      title: "Register",
    };
  },
  validations: {
    user: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      username: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToRegister = true;
          // Reset the regError if it existed.
          this.regError = null;
          return (
            this.$store
              .dispatch("auth/register", {
                email: this.user.email,
                password: this.user.password,
              })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                if (this.registerSuccess) {
                  this.$router.push(
                    this.$route.query.redirectFrom || {
                      path: "/",
                    }
                  );
                }
              })
              .catch((error) => {
                this.tryingToRegister = false;
                this.regError = error ? error : "";
                this.isRegisterError = true;
              })
          );
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "bengoboxAuth") {
          const {first_name,last_name,email, username, password } = this.user;
          if (first_name && last_name && email && username && password) {
            console.log(JSON.stringify(this.user));
            this.$store.dispatch("authfack/registeruser", this.user);
          }
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img src="@/assets/images/logo-dark.png" alt height="100" class="logo logo-dark" />
                <img src="@/assets/images/logo-light.png" alt height="100" class="logo logo-light" />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-8 col-xl-8">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-secondary">Register Account</h5>
                  <p class="text-muted">Get your free bengomall account now.</p>
                </div>
                <div class="p-2 mt-4 rounded-2">
                  <div v-if="notification.message" class="p-2 rounded-1" :class="'alert ' + notification.type" style="background-color: rgb(230, 200, 240);">
                      {{ notification.message }}
                    </div>
                  <b-form @submit.prevent="tryToRegisterIn()">
                    <div class="row">
                      <div class="col-sm-6">
                        <b-form-group id="fisrt_name-group" label="Fisrt Name" class="mb-3" label-for="first_name">
                          <b-form-input id="fisrt_name" v-model="user.first_name" type="text"
                            placeholder="Enter First Name" :class="{
                              'is-invalid': submitted && $v.user.first_name.$error,
                            }"></b-form-input>
                          <div v-if="submitted && !$v.user.first_name.required" class="invalid-feedback">
                            First Name is required.
                          </div>
                        </b-form-group>
                        <b-form-group id="email-group" label="Username" class="mb-3" label-for="username">
                          <b-form-input id="username" v-model="user.username" type="text" placeholder="Enter username"
                            :class="{
                              'is-invalid': submitted && $v.user.username.$error,
                            }"></b-form-input>
                          <div v-if="submitted && !$v.user.username.required" class="invalid-feedback">
                            Username is required.
                          </div>
                        </b-form-group>

                        <b-form-group id="email-group" label="Phone Number" class="mb-3" label-for="phone">
                          <b-form-input id="phone" v-model="user.phone" type="text"
                            placeholder="Enter phone e.g 0743793901" :class="{
                              'is-invalid': submitted && $v.user.phone.$error,
                            }"></b-form-input>
                          <div v-if="submitted && !$v.user.phone.required" class="invalid-feedback">
                            Phone Number is required.
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6">
                        <b-form-group id="last_name-group" label="Last Name" class="mb-3" label-for="last_name">
                          <b-form-input id="last_name" v-model="user.last_name" type="text" placeholder="Enter Last Name"
                            :class="{
                              'is-invalid': submitted && $v.user.last_name.$error,
                            }"></b-form-input>
                          <div v-if="submitted && !$v.user.last_name.required" class="invalid-feedback">
                            Last Name is required.
                          </div>
                        </b-form-group>
                        <b-form-group id="fullname-group" label="Email" label-for="email" class="mb-3">
                          <b-form-input id="email" v-model="user.email" type="email" placeholder="Enter email" :class="{
                            'is-invalid': submitted && $v.user.email.$error,
                          }"></b-form-input>
                          <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                            <span v-if="!$v.user.email.required">Email is required.</span>
                            <span v-if="!$v.user.email.email">Please enter valid email.</span>
                          </div>
                        </b-form-group>
                        <b-form-group id="password-group" label="Password" class="mb-3" label-for="password">
                          <b-form-input id="password" v-model="user.password" type="password" placeholder="Enter password"
                            :class="{
                              'is-invalid': submitted && $v.user.password.$error,
                            }"></b-form-input>
                          <div v-if="submitted && !$v.user.password.required" class="invalid-feedback">
                            Password is required.
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="auth-terms-condition-check" />
                      <label class="form-check-label" for="auth-terms-condition-check">I accept
                        <a href="javascript: void(0);" class="text-dark">Terms and Conditions</a></label>
                    </div>
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="warning" class="w-sm">Register</b-button>
                    </div>

                    <div class="mt-4 text-center">
                      <div class="signin-other-title">
                        <h5 class="font-size-14 mb-3 title">Sign up using</h5>
                      </div>

                      <ul class="list-inline">
                        <li class="list-inline-item">
                          <a href="javascript:void()" class="social-list-item bg-primary text-white border-primary">
                            <i class="mdi mdi-facebook"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="javascript:void()" class="social-list-item bg-info text-white border-info">
                            <i class="mdi mdi-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a href="javascript:void()" class="social-list-item bg-danger text-white border-danger">
                            <i class="mdi mdi-google"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-4 text-center">
                      <p class="text-muted mb-0">
                        Already have an account ?
                        <router-link to="/login" class="fw-medium text-primary">Login</router-link>
                      </p>
                    </div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} Codevertex. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by <a href="https://bengohub.co.ke/" target="_blank"
                  class="">Bengo Hub</a>
              </p>
            </div>
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style lang="scss" module></style>
